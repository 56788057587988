#gallery {
    background: transparent;
    max-width: 100%;
    max-height: 100vh;

    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 0px;
    grid-column-gap: 0px;
}

#gallery > div {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 25% center;
}

