.obs {
    background-color: black;
    color: white;
}

.obs.obs-grid {
    border-color: white;
}

textarea {
    background-color: transparent;/*rgb(28, 55, 117);*/
    border-radius: 0.5rem;
    font-family: inherit;
    color: white;
    border: 0.1rem solid rgba(255,255,255,0.3);
}

